// Login

.login-body .login-wrapper .login-panel .logo {
  text-align: center;
  margin-bottom: 10px !important;
}

.login-body .login-wrapper .login-panel .greeting {
  text-align: center;
  font-size: 30px;
  color: #1e1e1e;
  margin-top: 0;
}
.login-body .login-wrapper .login-panel .message {
  text-align: center;
  font-size: 16px;
  color: rgba(13, 74, 173, 0.28);
  margin: 0;
  padding: 0;
}

.login-body .login-wrapper .login-panel .logo img {
  width: auto !important;
  height: 40px !important;
}

.login-body .login-wrapper .login-panel > a, .login-body .login-wrapper .login-panel > form > button.forget-password {
  font-size: 14px;
}

.login-body .login-wrapper .login-panel > form > span {
  width: 85%;
  max-width: 247px;
  margin-bottom: 5px;
  margin-top:5px;
  background-color: #F6F7F7;
  border: 1.2px solid #D4D6D9;
  border-radius: 4px;
  color: #515C66;
}

.login-body .login-wrapper .login-panel > form > span > input{
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 12px 10px 12px 30px;
}

.login-body .login-wrapper .login-panel .p-password {
  width: 242px;
}
.login-body .login-wrapper .login-panel .p-password .p-password-input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 12px 10px 12px 30px;
}

.login-body .login-wrapper .login-panel > p > a, .login-body .login-wrapper .login-panel > form > button {
  margin-top: 5px;
}

.p-tabview .p-tabview-nav {
  background: none;
  border: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  background: none;
  border-radius: 6px;
  margin: 0 0 0 0;
}
.p-tabview .p-tabview-panels {
  background: none;
  padding: 1rem 0 0 0;
  border: 0 none;
  color: #69707A;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
}

p-tabview .p-tabview-nav li {
  margin-right: 10;
}

.layout-dashboard {
  .chart{
    overflow: auto;
  }

  .mobile-teams {
    display: none;
  }
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(52, 181, 111, 0.19);
  color: #1e1e1e;
}

.userItem {
  min-height: 12rem;
}

